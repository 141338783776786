import { createTheme } from "@mantine/core";

export const customTheme = createTheme({
  colors: {
    primary: [
      "#F7FDFA", // 25
      "#E9F8F1", // 50
      "#D2F0E3", // 100
      "#A5E2C6", // 200
      "#78D3AA", // 300
      "#4BC58D", // 400
      "#1EB671", // 500
      "#18925A", // 600
      "#126D44", // 700
      "#0C492D", // 800
      "#062417", // 900
    ],
    secondary: [
      "#F8FBFC", // 25
      "#E7F1F6", // 50
      "#CFE3EC", // 100
      "#9FC7D9", // 200
      "#6EACC7", // 300
      "#3E90B4", // 400
      "#0E74A1", // 500
      "#0B5D81", // 600
      "#084661", // 700
      "#062E40", // 800
      "#031720", // 900
    ],
  },
});
