import { User } from "@supabase/supabase-js";
import { useEffect } from "react";

// using this approach to avoid hydration errors:
// https://github.com/PostHog/posthog-js/issues/774#issuecomment-2461150623
export function Posthog({
  posthogKey,
  user,
}: {
  posthogKey: string;
  user: User | null;
}) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      import("posthog-js")
        .then(({ default: posthog }) => {
          if (!posthog.__loaded) {
            posthog.init(posthogKey, {
              api_host: "https://us.i.posthog.com",
              person_profiles: "always",
            });
            if (user) {
              posthog.identify(user.id);
            }
          }
        })
        .catch(console.error);
    }
  }, [posthogKey, user]);

  return null;
}
